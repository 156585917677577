.Leaderboard {
    height: 100vh;
    width: 100%;
    display: flex;
    overflow: auto;
}

.LeaderboardPodium {
    height: 400px;
    width: 100%;
    display: flex;
}