.container {
    position: fixed;
    right: 120px;
    top: 40px;
    height: 60px;
    width: 60px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, .35);  
    backdrop-filter: blur(5px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 800px) {
    .container {
        right: 20px;
        top: 100px;
    }
}