.container {
    position: fixed;
    right: 40px;
    bottom: 40px;
    z-index: 4;
}

@media screen and (max-width: 800px) {
    .buttonName {
        display: none;
    }
    .button {
        width: 50px;
    }
    .container {
        right: 20px;
        bottom: 20px;
    }
}