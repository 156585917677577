.ProfilePage {
    height: 100%;
    width: 100%;
    display: flex;
}

.TaskList {
    position: absolute;
    top: 200px;
    left:0;
    right:0;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
}

@media screen and (max-width: 800px) {
    .TaskList {
        width: 95%;
    }
}