.sign {
    height: 15vh;
    width: 10%;
    position: absolute;
    bottom: -10px;
    right: -35vh;
    left: 0px;
    margin-left: auto; 
    margin-right: auto;
    z-index: 4;
    background-image: url("assets/images/woodenSign.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
    cursor: pointer;
}

.title {
    margin-left: 15px;
    font-size: 23px;
}

.header {
    display: inline-flex;
    align-items: center;
    color: #000;
}

@media screen and (max-width: 500px) {
    .sign {
        right: -18vh;
        height: 10%;
        width: 20%;
    }
}