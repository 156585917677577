.container {
    position: absolute;
    width: 300px;
    height: 100px;
    text-align: left;
    margin: 40px 40px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, .35);  
    backdrop-filter: blur(5px);
    cursor: pointer;
    z-index: 4;
}

.child {
    display: inline-block;
    padding: 15px 5px;
    vertical-align: middle;
}

#userInformations {
    text-align: left;
    width: 70%;
}

#name {
    font-weight: bold;
}

#name, #department {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

#profilePicture {
    margin-left: 10px;
}

@media screen and (max-width: 800px) {
    .container {
        width: 250px;
        margin: 20px 20px;
    }
    .child {
        padding: 15px 0px;
    }
}