.page {
    width: 100%;
    height: 100%;
    display: grid;
    margin-top: 100px;
    grid-template-rows: repeat(3, 0fr);
    grid-row-gap: 20px;
}

.positionContainer {
    margin: 0 auto;
    margin-top: 10px;
    height: 50px;
    width: 60vh;
    min-width: 350px;
    padding-left: 60px;
    border-radius: 20px 10px 10px 20px;
    background-color: rgba(255, 255, 255, .35); 
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    border: 3px solid;
}

.podiumContainer {
    margin: 0 auto;
    margin-top: 30px;
    height: 50px;
    width: 60vh;
    min-width: 350px;
    padding-left: 60px;
    padding-right: 10px;
    border-radius: 20px 10px 10px 20px;
    background-color: rgba(255, 255, 255, .35); 
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    border: 2px solid;
}
.center {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
  }

.experience {
    font-weight: 600;
    margin-left: auto;
}

.profilePicture {
    border: 2px solid;
    margin-left: -90px;
}

.title {
    text-align: center;
    justify-self: center;
    margin-top: -10px;
    font-size: 30px;
}

.line {
    border-right: 6px dotted #fff;
    height: 100px;
    display: flex;
    width: 50%;
}

.gridItem {
    text-align: left;
    margin-left: 20px;
}

.gridItemCenter {
    text-align: center;
}

.gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
}

@media (max-width: 600px) {
    .gridContainer {
        grid-template-columns: 1fr; 
    }
}


@media screen and (max-width: 800px) {
    .center{
        width: 20%;
    }
}

@media screen and (max-width: 600px) {
    .podiumContainer {
        width: 60%;
        min-width: 300px;
        padding-left: 55px;
        margin-top: 20px;
    }
    .positionContainer {
        width: 60%;
        min-width: 300px;
        padding-left: 55px;
        margin-top: 5px;
    }
    .line {
        height: 50px;
    }
    .page {
        margin-top: 30px;
    }
    .title {
        width: 300px;
        font-size: 25px;
        margin-top: 10px;
    }

    .center {
        display: inline-block;
        width: 40%;
    }

    .experience {
        margin-top: 10%;
    }
}