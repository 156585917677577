.container {
    width: 300px;
    height: 110px;
    text-align: left;
    border-radius: 10px;
    position: fixed;
    bottom: 40px;
    left: 40px;
    background-color: rgba(255, 255, 255, .35);  
    backdrop-filter: blur(5px);
    padding: 15px;
    cursor: pointer;
    z-index: 4;
}

#level {
    margin-top: 5px;
    font-size: 17px;
    color: #000;
}

#nextLevel {
    margin-top: 4px;
    font-size: 15px;
    color: #000;
    float: right;
}

#experience {
    font-size: 17px;
    text-align: center;
    margin-top: 5px;
}

@media screen and (max-width: 800px) {
    .container {
        width: 110px;
        height: 70px;
        left: 20px;
        bottom: 20px;
        padding-top: 0;
    }
    .levelText {
        display: none;
    }
    #level {
        font-weight: 550;
        font-size: small;
        margin: 0;
    }

    #nextLevel{
        font-weight: 550;
        font-size: small;
        margin-top: 0;
        margin-bottom: 0;
    }

    #experience {
        margin-top: 4px;
        font-size: smaller;
    }
}