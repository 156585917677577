.userPin {
    height: 74px;
    width: 73px;
    position: inherit;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.firstPin {
    height: 74px;
    width: 73px;
    position: inherit;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -85px;
}

.initials {
    width: 75px;
    height: 75px;
}

.profilePicture {
    position: absolute;
    left: 14px;
    top: 9px;
}

.icon {
    position: absolute;
    left: 0px;
    color: rgba(255, 255, 255, .55);
}

@media screen and (max-width: 900px) {
    .icon {
        width: 55px;
        height: 55px;
    }
    
    .profilePicture{
        width: 35px !important;
        height: 33px !important;
        left:10px !important;
    }

    .profilePicture div {
        font-size: 1rem;
    }

    .userPin{
        height: 55px;
        width: 55px;
    }
    
}