.container {
    position: fixed;
    width: 300px;
    height: 60px;
    text-align: left;
    border-radius: 10px;
    bottom: 170px;
    left: 40px;
    background-color: rgba(255, 255, 255, .35);  
    backdrop-filter: blur(5px);
    z-index: 5;
}

.elements {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
}

.switch {
    margin-left: 10px;
    margin-right: 10px;
}

@media screen and (max-width: 800px) {
    .container {
        width: 200px;
        left: 20px;
        top: 140px;
        font-size: 14px;
        align-items: center;
    }
}