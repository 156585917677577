.TaskList {
    border-radius: 20px;
    background-color: rgba(255, 255, 255, .35);  
    backdrop-filter: blur(5px);
    min-width: 400px;
}

.TabTitle {
    font-size: 16px;
}

@media screen and (max-width: 800px) {
    .TaskList {
        min-width: 200px;
        margin-top: -40px;
    }
    .TabTitle {
        font-size: 13px;
    }
}