.HomePage {
    height: 100vh;
    width: 100%;
    display: flex;
}

.background {
    height: 100vh;
    background-image: linear-gradient(315deg, #63d471 0%, #233329 74%);
}

.container {
    background-color: #fff;
    height: 80%;
    max-height: 450px;
    width: 80%;
    max-width: 450px;
    border-radius: 20px;
    text-align: center;
}

.ainosLogo {
    margin: 70px auto;
    width: 300px;
}