.container {
    background-color: #fff;
    height: 80%;
    max-height: 450px;
    width: 80%;
    max-width: 450px;
    border-radius: 20px;
    text-align: center;
}

.ainosLogo {
    margin: 70px auto;
    width: 300px;
}

.microsoftButton {
    margin-top: 100px;
}

@media screen and (max-width: 800px) {
    .ainosLogo {
        width: 200px;
        margin-top: 60px;
    }
    .microsoftButton {
        margin-top: 5vh;
    }
    .container {
        height: 40%;
    }
}