.container {
    height: 70%;
    position: absolute;
    bottom: 0px;
    right: 0px; 
    left: 0px; 
    margin-left: auto; 
    margin-right: auto; 
    z-index: 3;
    max-height: 60vw;
}

#globalMountain {
    background-image: url("assets/images/globalMountain.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
}

#functionnalMountain {
    background-image: url("assets/images/functionnalMountain.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
}

@media screen and (max-width: 800px) {
    .container {
        height: 100%;
    }
}