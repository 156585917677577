.center {
    position: absolute;
    right: -200px;
}
  
#cloud {
    background: rgba(255, 255, 255);
    width: 200px; 
    height: 66px;
    border-radius: 150px;	
    box-shadow: 10px 10px rgba(0,0,0,0.2);
    animation: move 30s infinite linear;
}
  
#cloud:after {
    content: '';
    background: rgba(255, 255, 255);
    position: absolute;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    top: -35px;
    left: 30px;
}
  
#cloud:before {
    content: '';
    background: rgba(255, 255, 255);
    position: absolute;
    width: 100px;
    height: 90px;
    border-radius: 50%;
    top: -60px;
    right: 30px;
}

@keyframes move {
    0% {
      transform: translatex(0);
    }
    100% {
      transform: translatex(-120vw);
    }
}

@media screen and (max-width: 800px) {
    .center {
        top: 50%;
        right: 0%;
    }

    @keyframes move {
        0% {
          transform: translatex(0);
        }
        100% {
          transform: translatex(-170vw);
        }
    }
}