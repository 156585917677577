.version-info {
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  z-index: 1000;
}

@media screen and (max-width: 800px) {
  .version-info {
    display: none;
  }
}
