.TaskListContent {
    text-align: center;
    height: 120px;
    width: 100%;
}

.Badge {
    height: 90px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: top;
    background-size: 90px 90px;
}

.pending {
    position: absolute;
    top: -20%;
    left: 50%;
    overflow: hidden;
    width: 100%;
    height: auto;
    text-align: right;
}